<template>
  <el-dialog title="Comanda acceptata" :visible.sync="ArataDialog" width="90%"  append-to-body >
    <h6 style="margin-bottom:20px;">Produse comanda acceptata {{Comanda.Id}}</h6>   
    <el-row :gutter="15" style="margin-bottom:10px; padding-left:10px;">
      <el-col :md="5">Nume produs</el-col>
      <el-col :md="3">Detalii</el-col>
      <!-- <el-col :md="3">Cod saga</el-col> -->
      <el-col :md="6">Cod produs</el-col>
      <el-col :md="3">Cantitate</el-col>
      <el-col :md="3">Pret</el-col>
      <el-col :md="3">Valoare</el-col>
    </el-row>

    <el-row v-for="item in Grupe" :key="item.NrOrdine" style="margin-bottom:15px;background-color:#f5f6f7; padding:5px 10px;">
            
      <el-col v-if="item.ShowPiese" style="margin-bottom:10px;">
        <b>Piese</b>
        <el-row :gutter="15" v-for="p in item.Piese" :key="p.Id">                
          <el-col :md="5" > {{p.NumeProdus}} </el-col>
          <el-col :md="3"><p>{{p.Detalii}}&nbsp;</p></el-col>      
          <!-- <el-col :md="3">
            <el-input v-model="p.CodSaga"></el-input>
          </el-col>       -->
          <el-col :md="6">
            <el-input v-model="p.CodProdus"></el-input>
          </el-col>      
          <el-col :md="3">{{p.Cantitate}}</el-col>      
          <el-col :md="3">{{p.PretUnitar | format_money}} lei</el-col>      
          <el-col :md="3">{{p.Valoare | format_money}} lei</el-col>      
        </el-row>    
      </el-col>

      <el-col v-if="item.ShowManopera" style="margin-bottom:10px;">
        <b>Manopera</b>
        <el-row :gutter="15" v-for="m in item.Manopera" :key="m.Id">                
          <el-col :md="5" > {{m.NumeProdus}} </el-col>
          <el-col :md="3"><p>{{m.Detalii}}&nbsp;</p></el-col>      
          <!-- <el-col :md="3">
            <p>&nbsp;</p>
            <el-input v-model="m.CodSaga"></el-input>
          </el-col>       -->
          <el-col :md="6">
            <p>&nbsp;</p>
            <!-- <el-input v-model="m.CodProdus"></el-input> -->
            </el-col>      
          <el-col :md="3">{{m.Cantitate}}</el-col>      
          <el-col :md="3">{{m.PretUnitar | format_money}} lei</el-col>      
          <el-col :md="3">{{m.Valoare | format_money}} lei</el-col>      
        </el-row>    
      </el-col>

      <el-col v-if="item.ShowKit" style="margin-bottom:10px;">
      <b>Kit</b>
      <el-row :gutter="15" v-for="k in item.Kit" :key="k.Id">                
        <el-col :md="5" > {{k.NumeProdus}} </el-col>
        <el-col :md="3"><p>{{k.Detalii}}&nbsp;</p></el-col>      
        <!-- <el-col :md="3">
          <p>&nbsp;</p>
          <el-input v-model="k.CodSaga"></el-input>
        </el-col>       -->
        <el-col :md="6">
          <p>&nbsp;</p>
          <!-- <el-input v-model="k.CodProdus"></el-input> -->
        </el-col>      
        <el-col :md="3">{{k.Cantitate}}</el-col>      
        <el-col :md="3">{{k.PretUnitar | format_money}} lei</el-col>      
        <el-col :md="3">{{k.Valoare | format_money}} lei</el-col>      
      </el-row>    
    </el-col>

  </el-row>



  <el-row >
    <el-col :md="6" :offset="17">
      <el-table class="full-width" :data="TabelTotal">
        <el-table-column prop="nume"></el-table-column>
        <el-table-column prop="totalComanda">
          <template slot-scope="scope">
          
              {{scope.row.totalComanda | format_money}} lei
            
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>

  <span slot="footer" class="dialog-footer">
    <el-button @click="ArataDialog = false">Anuleaza</el-button>
    <el-button type="primary" @click="salveaza_comanda">Confirma</el-button>
  </span>
</el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage'

    export default {
        name: "ComandaFurnizor",
        extends: BasePage,
        data(){
            return {
                Grupe: { Piese: [], Manopera: [], Kit: []},
                Comanda: {},
                loading : false,
                ArataDialog : false,
                rules : {},
                TabelTotal: [{
                  nume: 'Total',
                  totalComanda: Number
                }],
                TotalComanda: Number,
                totalPiese: [],
                totalManopera: [],
                totalKit: []
            }        
        },
        methods: {
           async show_me(idComanda) {
            this.ArataDialog = true
            this.loading = true
            var response = await this.post('ComenziFurnizori/get_detalii_comanda',{idComanda:idComanda})
            this.Comanda = response.Comanda
            this.Grupe = response.Grupe

            this.Grupe.forEach(g => {     
                         
              if(g.Piese.length > 0){
                g.ShowPiese = true;
                g.Piese.forEach(p => {
                    this.totalPiese.push(parseFloat(p.Valoare))
                })
              } 
              if(g.Manopera.length > 0){
                g.ShowManopera  = true;
                g.Manopera.forEach(m => {
                    this.totalManopera.push(parseFloat(m.Valoare))
                })
              } 
              if(g.Kit.length > 0){
                g.ShowKit = true;
                g.Kit.forEach(k => {                    
                    this.totalKit.push(parseFloat(k.Valoare))
                })
              }      

              let Total = this.totalPiese.concat(this.totalManopera).concat(this.totalKit)

              this.TabelTotal[0].totalComanda  = Total.reduce((a, b) => a + b)                
              this.$forceUpdate()
            });
           
            this.loading = false 
           },

           async salveaza_comanda () {
            this.loading = true
            var response = await this.post('ComenziFurnizori/salveaza_detalii_comanda',{comanda:this.Comanda, grupe_produse: this.Grupe})
            this.loading = false 
            this.ArataDialog = false
           },
           handleClose () {
             
           },              
        }
    }
</script>

<style lang="less">
    .full-width {
        width: 100%;
    }

    .margin-bottom30 {
      margin-bottom:30px;
    }
       
</style>