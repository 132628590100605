<template>
    
    <el-dialog title="Checklist 360" :visible.sync="showPopup" class="large-dialog-class" width="80%" append-to-body :show-close='false' :close-on-click-modal='false'>
        <el-button @click="skip_check">Skip check</el-button> Skipped: <span  v-if="checklist.SkipCheck==1"><b style="color:red;margin-right:10px">DA </b> Motiv: '{{checklist.MotivSkip}}'</span><span v-else><b>NU</b></span>
        <el-form label-position="top" :inline="false" label-width="100%" ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >           
            <el-row v-for="item in lista_verificari" :key=" 'intrebare-' + item.OrderNo ">
                <el-col :md="24">
                    <el-row style="margin-bottom: 10px; text-align:center; text-transform: uppercase;">
                        <el-col :md='24' style='font-size: 12px'>
                            <b>{{item.Nume}}</b>
                            
                        </el-col>
                    </el-row>
                    <el-row :gutter='10'>
                        <el-col :md="24">
                            <div v-if="item.CampOptional">
                                <el-input size="small" style="padding: 0 5px; margin-bottom:8px;" disabled v-model="item.Raspuns"></el-input>
                            </div>
                            <div v-else>
                                <el-col :span="8">
                                    <el-radio v-if="mod_checklist=='editare'" class='full-width bine' v-model="item.Raspuns" label="bine" size='small' border ></el-radio>
                                    <span v-else>{{item.Raspuns}}</span> 
                                </el-col>
                                <el-col :span="8">
                                    <el-radio v-if="mod_checklist=='editare'" class='full-width satisfacator' v-model="item.Raspuns" label="satisfacator" size='small' border></el-radio>
                                    <span v-else>{{item.Raspuns}}</span>
                                </el-col>
                                <el-col :span="8">
                                    <el-radio v-if="mod_checklist=='editare'" class='full-width urgent' v-model="item.Raspuns" label="urgent" size='small' border></el-radio>
                                    <span v-else>{{item.Raspuns}}</span>
                                </el-col>
                            </div>
                            
                            <el-col :span="24">
                                <el-input v-if="mod_checklist=='editare'" size='small' v-model="item.Observatii" placeholder="Observatii"></el-input>
                                <span v-else>{{item.Observatii}}</span>
                            </el-col>
                        </el-col>
                    </el-row>
                    <hr style="margin:4px 0px">
                </el-col>                     
            </el-row>
            <el-row >
                <el-col style="margin-bottom: 10px; margin-left: 7.5px;">Observatii masina</el-col>
                <el-col :md="24" style="margin-left: 7.5px;">
                    <el-input type="textarea" autosize v-model="checklist.Observatii" placeholder="Observatii masina"></el-input>
                </el-col>
            </el-row>                            
        </el-form>
        
        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" v-if="mod_checklist=='editare'" > Salveaza </el-button>
        </span>      

        <el-dialog title="Motiv" :visible.sync="showSkipCheck" class="large-dialog-class" width="50%" append-to-body :close-on-click-modal='false'>
            <el-radio-group v-model="radio" @change="change_radio">
                <el-radio :label="1">Sezon(masini in asteptare)</el-radio>
                <el-radio :label="2">Clientul refuza check-ul</el-radio>
                <el-radio :label="3">Check facut recent</el-radio>
                <el-radio :label="4">Alt motiv</el-radio>               
            </el-radio-group>

            <el-input
            style="margin-top:30px"
            v-if="showAltMotiv"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 4}"
            placeholder="Motiv"
            v-model="checklist.MotivSkip">
            </el-input>
            
            <span slot="footer" class="dialog-footer" >
                <span class="eroare" v-if="arataMesajEroare"> Este obligatoriu sa selectati un motiv </span>
                <el-button @click="showSkipCheck=false"     > Renunta  </el-button>
                <el-button :disabled="arataMesajEroare" type="primary" @click="save_skip_check" > Salveaza </el-button>
            </span>   
        </el-dialog>
        
    </el-dialog>

    

</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Check360_dialog",
        extends: BasePage,
        components:{
        },
        computed:{
            showAltMotiv(){
                let ret = false
                if(this.radio == 4)
                    ret = true
                return ret
            },
            arataMesajEroare(){
                let ret = false
                if(this.checklist.MotivSkip == '')
                    ret = true
                
                return ret
            }
        },
        data () {
            return {
                showPopup: false,
                fisa: [],
                checklist: {},
                mod_checklist: 'editare',
                lista_verificari: [],
                IdFisaIntrare: -1,
                showSkipCheck:false,
                radio:'',
                motiv:'',
                tipuri_motive:["Sezon(masini in asteptare)","Clientul refuza check-ul","Check facut recent"],
                is_skipped:false,
            }
        },
        methods: {
            show_me: async function(fisa) {
                this.showPopup          = true
                this.fisa               = fisa
                this.IdFisaIntrare      = fisa.Id
                var            result   = await this.post("Checklist/get_lista_verificari", { fisa: this.fisa })
                this.lista_verificari   = result.ListaVerificari
                this.checklist          = result.Checklist
                
                
            },
            async show_me_for_edit(idChecklist) {
              this.showPopup          = true;
              var            result   = await this.post("Checklist/get_fisa_completa", { idChecklist: idChecklist } )
              this.lista_verificari   = result.ListaVerificari
              this.checklist          = result.Checklist
              this.IdFisaIntrare      = result.IdFisaIntrare
            },
            save: async function() {
                var result      = await this.post("Checklist/save_checklist", { lista_verificari: this.lista_verificari, checklist: this.checklist, idFisaIntrare: this.IdFisaIntrare, is_skipped: this.is_skipped, motiv:this.motiv } );
                this.showPopup  = false
                
                this.$emit('saved',{IdChecklist:result.IdChecklist,IdFisaIntrare: this.IdFisaIntrare})
            },
            skip_check(){
                this.showSkipCheck          = true
                this.checklist.SkipCheck    = 1
            },
            change_radio(val){
                if(this.radio != 4)
                    this.checklist.MotivSkip = this.tipuri_motive[this.radio - 1]
                if(this.radio == 4)
                    this.checklist.MotivSkip = ''
                
            },
            save_skip_check(){                                
                this.lista_verificari.forEach(v => {
                    v.Raspuns = 'bine'
                })
                // this.is_skipped             = true
                // this.checklist.SkipCheck    = true
                this.showSkipCheck          = false                
            }
        },
        mounted: function() {
            
        }
    };
</script>

<style lang="less" >
    .full-width {
        width: 100%;
    }

    .satisfacator.el-radio.is-bordered.is-checked{
        border-color: #E6A23C;
    }
    .urgent.el-radio.is-bordered.is-checked{
        border-color: #F56C6C;           
    }
    .eroare {
      color: red;
      font-size: 12;
      margin-right:10px
	}
    
    
    

</style>