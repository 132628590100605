<template>
    <el-dialog title="Inchidere deviz" :visible.sync="ArataDialog" width="30%"  append-to-body >
        <h6>Creat de: {{utilizator}} / {{info.DataIntrare}}</h6>

        <el-table :data="data" style="margin-bottom:30px">

            <el-table-column label="Checklist" prop="IdChecklist">
                <template slot-scope="scope">
                    <div v-if="scope.row.IdChecklist > 0">
                        <a :href="BASE_URL+'/Checklist/print_pdf/'+scope.row.IdCheckList" target="_blank">{{scope.row.IdCheckList}}</a>
                    </div>
                    <div v-else>{{scope.row.IdCheckList}}</div>
                </template>
            </el-table-column>
            <el-table-column label="Comanda" prop="IdComanda">
                <template slot-scope="scope">
                    <div v-if="scope.row.IdComanda > 0">
                        <a :href="BASE_URL+'/ComenziFurnizori/print_pdf/'+scope.row.IdComanda" target="_blank">{{scope.row.IdComanda}}</a>
                    </div>
                    <div v-else>{{scope.row.IdComanda}}</div>
                </template>
            </el-table-column>
        </el-table>

        <div v-if="mesaj != ''" style="text-align:center">
            <el-tag  type="success"> {{mesaj}} </el-tag>
        </div>

        <div v-else>
            <el-form :inline="true" :model="form" >
                <el-form-item label="Pin validare">
                    <el-input class="full-width" type="password" placeholder='Pin' v-model='form.pin'> </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button  plain type="success" @click="login">OK</el-button>
                </el-form-item>            
            </el-form>  
        </div>
                      
        
        <span slot="footer" class="dialog-footer">
            <el-button @click="ArataDialog = false">Anuleaza</el-button>
            <el-button :disabled="neconfirmat" type="primary" @click="confirma">Confirma</el-button>
        </span>
    </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from '@/pages/BasePage'
export default {
    name:'Validare_deviz',
    extends: BasePage,
    data(){
        return {
            eplicatie:'',
            pin:'',
            info:{},
            data:[],
            ArataDialog:false,
            neconfirmat:true,
            form:{
                pin:''
            },
            BASE_URL:'',
            utilizator:'',
            mesaj: ''
        }
    },
    methods:{
        async get_info(row){
            this.info           = row
            this.neconfirmat    = true
            this.mesaj          = ''
            this.ArataDialog    = true
            var response        = await this.post("devize/info_deviz",{id:row.Id})
            this.data           = response.data
            this.BASE_URL       = settings.BASE_URL
            this.utilizator     = this.data[0].IdUtilizatorAdaugare
        },
        async login(){
            var response = await this.post("devize/check_pin",{pin:this.form.pin})
            if(!response.Error)
            {
                // this.$message({type:'success',message:response.Mesaj})
                this.mesaj          = response.Mesaj
                this.neconfirmat    = false
                this.form.pin       = ''
            }
            else
                this.$message({type:'warning',message:"Parola nu corespunde"})
        },
        async confirma(){
            var response        = await this.post("devize/inchidere",{id:this.info.Id})
            this.ArataDialog    = false
            this.$emit('save')
        },
        
    }
}
</script>
