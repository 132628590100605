<template>
    <el-dialog title="Adaugare observatie" :visible.sync="showPopup" class="my-dialog-class" width="30%">
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
                <el-col >
                    <el-form-item label='Observatie' prop="Mesaj">
                        <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" class='full-width' v-model='selectedObject.Mesaj' />
                    </el-form-item>
                </el-col>

            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="resetForm('my-form')"           > Renunta  </el-button>
			<el-button type="primary" @click="save('my-form')" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Masini_observatii_dialog",
        extends: BasePage,
        props: {
            idMasina:{
                required:true
            }
            
        },
        data () {               
            return {
                showPopup: false,
                selectedObject: { Mesaj: '', IdMasina: ''},                    
                rules: {
                    Mesaj: [ { required: true, message: 'Campul este obligatoriu' } ]                   
                }
            }
        },
        methods: {
            show_me( id ) {
                this.showPopup = true;  
                this.selectedObject.IdMasina = this.idMasina              
            },          
            save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("observatii_masini/save_observatie", { object: this.selectedObject } );
                        this.resetForm()
                        this.$emit("save");
                    } else console.log('eroare validare formular!')
                });
            },
            resetForm() {
				this.$refs['my-form'].resetFields()
				this.showPopup = false
			}
        },
        mounted: function() {

        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>