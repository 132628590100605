<template>
  <el-dialog title="Programare" :visible.sync="showPopup" width="80%" >
    <el-form label-position="top" :inline="false" :rules="rules" label-width="100%" :model='selectedObject' ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
        <el-row :gutter="15" >  
          <el-col :md='12'>
            <el-form-item label='Mecanic' prop="IdMecanic">
              <el-select v-model='selectedObject.IdMecanic' class='full-width' filterable>
                  <el-option label='Fara' value='-1'></el-option>        
                  <el-option v-for='item in Utilizatori' :key="'utilizatori' + item.Id" :label='item.Nume' :value='item.Id'></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md='12'>
            <el-form-item label='Data start' prop="DataStart">
              <el-date-picker  class='full-width' v-model='selectedObject.DataStart' type='datetime' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="24">
            <h6>Produse</h6>
          </el-col>
        </el-row>
<!-- <pre> {{Grupe}} </pre> -->
        <el-row :gutter="15" v-if="showSelectProdus">
          <el-col v-if="showSelectProdus" :span="12">
            <el-autocomplete class="full-width" v-model="nume_produs" placeholder="Cauta produs" style="margin-bottom:40px"
              :fetch-suggestions="cautaProdus" @select="handleSelectProdus">      
              <template slot-scope="{ item }">
                <div class="nume">{{ item.ProdusComplet.Nume }}</div>
                <div class="pret" >{{item.ProdusComplet.Pret |format_money}} lei</div>
              </template>                      
            </el-autocomplete>
          </el-col>          
        </el-row>

        <el-row :gutter="15" style="margin-bottom:10px; padding-left:10px;">

          <el-col :md="8">Nume produs</el-col>          
          <el-col :md="3">Cod</el-col>          
          <el-col :md="3">Cantitate</el-col>
          <el-col :md="3">Pret</el-col>
          <el-col :md="3">Valoare</el-col>
          <el-col :md="3">Cantitate retur</el-col>
        </el-row>

        <el-row v-for="item in Grupe" :key="item.NrOrdine" style="margin-bottom:15px;background-color:#f5f6f7; padding:5px 10px;">
			<el-checkbox v-model="item.Selectat" @change="selectGrupa(item)" style="margin-bottom:20px;">Selecteaza toate produsele din grupa</el-checkbox>               
				<el-col v-if="item.ShowPiese" style="margin-bottom:10px;">
					<b>Piese</b>
				<el-row :gutter="15" v-for="p in item.Piese" :key="p.Id">                
					<el-col :md="8" > {{p.NumeProdus}} </el-col>
					<el-col :md="3" > 
						<el-input style="margin-top: -10px;" :disabled="disabledCod" v-model="p.CodProdus"></el-input>
					</el-col>
						
					<el-col :md="3">{{p.Cantitate}}</el-col>      
					<el-col :md="3">{{p.PretUnitar | format_money}} lei</el-col>      
					<el-col :md="3">{{p.Valoare | format_money}} lei</el-col>
					<el-col :md="3">
						<template>
							<div v-if="p.CantitateReturnata > 0" style="margin-top: -6px;"> 
								<el-tag v-if="p.EsteRetur == '1'" type="danger" style="font-size:15px">{{p.CantitateReturnata}}</el-tag> 
								<el-tag v-else type="success" style="font-size:15px">{{p.CantitateReturnata}}</el-tag> 
							</div>
							<div v-else> - </div>
						</template>					 
					</el-col>
				</el-row>  	  
			</el-col>

			<el-col v-if="item.ShowManopera" style="margin-bottom:10px;">
				<b>Manopera</b>
				<el-row :gutter="15" v-for="m in item.Manopera" :key="m.Id">                
					<el-col :md="8" > {{m.NumeProdus}} </el-col>
					<el-col :md="3" > 
						<el-input style="margin-top: -10px;" :disabled="disabledCod" v-model="m.CodProdus"></el-input>
					</el-col>
					
					<el-col :md="3">{{m.Cantitate}}</el-col>      
					<el-col :md="3">{{m.PretUnitar | format_money}} lei</el-col>      
					<el-col :md="3">{{m.Valoare | format_money}} lei</el-col>     
					<el-col :md="3">
						<template>
							<div v-if="m.CantitateReturnata > 0"  style="margin-top: -6px;"> 
								<el-tag v-if="m.EsteRetur == '1'" type="danger" style="font-size:15px">{{m.CantitateReturnata}}</el-tag> 
								<el-tag v-else type="success" style="font-size:15px">{{m.CantitateReturnata}}</el-tag> 
							</div>
							<div v-else> - </div>							
						</template>					 
					</el-col> 
				</el-row>    
			</el-col>

			<el-col v-if="item.ShowKit" style="margin-bottom:10px;">
				<b>Kit</b>
				<el-row :gutter="15" v-for="k in item.Kit" :key="k.Id">                
					<el-col :md="8" > {{k.NumeProdus}} </el-col>
					<el-col :md="3" > 
						<el-input style="margin-top: -10px;" :disabled="disabledCod" v-model="k.CodProdus"></el-input>
					</el-col>
					
					<el-col :md="3">{{k.Cantitate}}</el-col>      
					<el-col :md="3">{{k.PretUnitar | format_money}} lei</el-col>      
					<el-col :md="3">{{k.Valoare | format_money}} lei</el-col>      
					<el-col :md="3">
						<template>
							<div v-if="k.CantitateReturnata > 0"  style="margin-top: -6px;"> 
								<el-tag v-if="k.EsteRetur == '1'" type="danger" style="font-size:15px">{{k.CantitateReturnata}}</el-tag> 
								<el-tag v-else type="success" style="font-size:15px">{{k.CantitateReturnata}}</el-tag> 
							</div>
							<div v-else> - </div>
						</template>					 
					</el-col>
				</el-row>    
			</el-col>

      	</el-row>

         
    </el-form>
	
    <span slot="footer" class="dialog-footer" >
		<p class="eroare" v-if="arataMesajEroare"> {{mesaj_eroare}} </p>
		<el-button v-if="existaRetur" type="success" @click="acceptaRetururi()" > Accepta retur </el-button>
		<el-button v-if="disabledCod && mode == 'add'" type="info" @click="modificaCod()" > Editeaza coduri</el-button>
		<el-button v-if="!disabledCod && mode == 'add'" type="success" @click="confirmaCod()" > Confirma coduri</el-button>
		<el-button @click="resetForm('my-form')" > Renunta  </el-button>
		<el-button type="primary" @click="save('my-form')" > Salveaza </el-button>
    </span>

  </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';
    import moment from 'moment';

    export default {
        name: "Programari_dialog",
		extends: BasePage,
		computed: {
			arataMesajEroare(){
				if(this.show_eroare)
				{
					if(this.from == 'calendar')
						return false
					else
						return true
				}
				else
					return false
			},
			existaRetur(){
				
				var ret = false
				if(this.Grupe.length > 0){
					this.Grupe.forEach(g => {
						if(g.Piese.length > 0){
							g.Piese.forEach(p => {
								if(p.EsteRetur == '1')
									ret = true
							})
						}
						if(g.Manopera.length > 0){
							g.Manopera.forEach(m => {
								if(m.EsteRetur == '1')
									ret = true
							})
						}
						if(g.Kit.length > 0){
							g.Kit.forEach(k => {
								if(k.EsteRetur == '1')
									ret = true
							})
						}											
					})
				}				

				return ret
			}
		},
        data () {
            return {
                showPopup: false,
                mode: 'add',
                IdComandaFurnizor : null,
                show_eroare: true,
                mesaj_eroare: 'Este obligatoriu sa selectati minim un produs.',
                loading:true,
                selectedObject: { IdMecanic: '-1' , DataStart: ''},
                show_piese: false,
                show_manopera: false,
                show_kit: false,
                multipleSelection: [],
                Utilizatori:[],
                arata_checkboxuri: false,
                rules: {
                    IdMecanic: [ { required: true, message: 'Campul este obligatoriu', trigger:'blur' } ], 
                    DataStart: [ { required: true, message: 'Campul este obligatoriu', trigger:'blur' } ]
                },
                Grupe: { Piese: [], Manopera: [], Kit: []},
                id_programare: '',
                selectii: [],
                showSelectProdus:false,
				nume_produs:'',
				Produse: [],
				ProdusComplet:{},
				from:'',
				disabledCod:true
            }
        },
        methods: {
            show_me: async function( idProgramare,idComandaFurnizor=null, from=null) {
				this.from = from
				this.showSelectProdus   = false
				this.show_eroare        = true
				this.mesaj_eroare       = 'Este obligatoriu sa selectati minim un produs.'
				this.showPopup          = true;
				this.id_programare      = idProgramare;
				this.loading            = true
				this.IdComandaFurnizor  = idComandaFurnizor
				this.mode               = "edit";
				if( idProgramare == -1 )
					this.mode = "add";

				var result              = await this.post("programari/get_info_for_dialog", { idProgramare: idProgramare, idComandaFurnizor: idComandaFurnizor, mode:this.mode } );              
				this.Utilizatori        = result.Utilizatori
				this.selectedObject.DataStart = moment().add(1, 'days').format("YYYY-MM-DD 12:00:00")

				if(result.Programare != undefined){
					this.selectedObject = result.Programare
				} 

				this.Grupe              = result.Grupe
				
				this.Grupe.forEach(g => {     
					if(g.Piese.length > 0) g.ShowPiese = true;                                
					if(g.Manopera.length > 0) g.ShowManopera  = true;
					if(g.Kit.length > 0) g.ShowKit = true;                                  
				})

				let filter = this.Grupe.filter(g => g.Selectat == true)
				if (filter != '') {
						this.show_eroare  = false
						this.mesaj_eroare = ''
					}
				
				this.loading            = false

					console.log('from',from);
					
				if(from == 'calendar')
					this.showSelectProdus = true

				this.disabledCod = true	
            },
            set_checkbox_state(checkState){
              this.arata_checkboxuri = checkState
            },
            handleSelectionChange(val) {
              this.multipleSelection = val;
            },

            save: async function() {                           
              
              this.$refs['my-form'].validate( async(valid) => {
                if (valid) {                                                     
                 
                 if(!this.show_eroare){
                   
                    this.loading = true
                    await this.post("programari/save", { mode: this.mode, id_programare: this.id_programare, object: this.selectedObject, grupe_produse: this.Grupe, idComandaFurnizor: this.IdComandaFurnizor} );
                    this.$message({type: 'success', message: 'Programarea a fost inregistrata'});
                    this.resetForm()
                    this.loading = false
                    this.show_eroare = false;
                    this.$emit("save");
				 } 
				 else 
					console.log('eroare validare!')
                }
              });
            },
            resetForm() {
              this.$refs['my-form'].resetFields()
              this.showPopup = false
			},
			
			selectGrupa(item){            
				if(item.Selectat){
				this.Grupe.forEach(g => {
					if(g.Id == item.Id){
					g.Piese.forEach(p => {p.Selectat = true})
					g.Manopera.forEach(m => {m.Selectat = true})
					g.Kit.forEach(k => {k.Selectat = true})
					}
				})
				} else {
				this.Grupe.forEach(g => {
					if(g.Id == item.Id){
					g.Piese.forEach(p => {p.Selectat = false})
					g.Manopera.forEach(m => {m.Selectat = false})
					g.Kit.forEach(k => {k.Selectat = false})
					}
				})
				}
				
				var filter  = '';
				filter      = this.Grupe.filter(g => g.Selectat == true)
				
				if (filter != '') {
				this.show_eroare  = false
				this.mesaj_eroare = ''
				}
				else {
				this.show_eroare  = true
				this.mesaj_eroare = 'Este obligatoriu sa selectati minim un produs.'
				}             
				},
				// querySearch
				async cautaProdus(queryString, cb){
					if(queryString != '')
					{
						var response = await this.post("dashboard_mecanic/search_produs_rapid", {queryString: queryString})
						cb(response.Results)
					}
			},
			handleSelectProdus(item){                
				// this.ProdusComplet = item.ProdusComplet
				
				let cosGol = true            
				this.Produse.forEach(p => {
					if(p.Id === item.ProdusComplet.Id) {
						cosGol = false
						p.Cantitate++
					}                                     
				})

				if(cosGol) {
					this.$set(item.ProdusComplet, 'Cantitate', 1)                        
					this.Produse.push(item.ProdusComplet)
				}
				
				this.nume_produs = ''
			},							

			acceptaRetururi(){
				this.Grupe.forEach(g => {
					if(g.Piese.length > 0){
						g.Piese.forEach(p => {
							p.EsteRetur = 0;
						})
					}
					if(g.Manopera.length > 0){
						g.Manopera.forEach(m => {
							m.EsteRetur = 0;
						})
					}
					if(g.Kit.length > 0){
						g.Kit.forEach(k => {
							k.EsteRetur = 0;
						})
					}											
				})
			},
			// 
			modificaCod(){
				this.disabledCod = false
			},
			confirmaCod(){
				this.disabledCod = true
			}

        },
        mounted(){
          
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }
    .eroare {
      color: red;
      font-size: 12
	}
	
	.nume {
        width: 40%;
        float:left;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .pret {
        width: 20%;
        float:left;
    }

</style>