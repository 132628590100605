<template>
    <el-card class="full-width margin-bottom30"> 
      <div slot="header" class="clearfix">
          <span>Programari</span>                        
      </div>
      <el-table :data="Info" v-loading="loading" :row-class-name="tableRowClassName">
        <el-table-column prop="DataStart" label="Data"></el-table-column>
        <el-table-column label="Actiuni">
            <template slot-scope="scope">                    
                <el-tooltip v-if="scope.row.Status != 'incheiata'" content="Modifica"> 
                  <el-button type="primary" size="small" plain  @click="modifica(scope.row)" icon="el-icon-edit"></el-button>
                </el-tooltip>
            </template>
        </el-table-column>
      </el-table>
      <programari-dialog @save='get_info' ref="programariDialog"></programari-dialog>
    </el-card>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage'
    import ProgramariDialog from '@/pages/programari/Programari_dialog'

    export default {
        name: "Programari",
        extends: BasePage,
        props:['Masina'],
        data(){
            return {
                Info: [],
                loading: true
            }        
        },
        methods: {
          async get_info(){        
            var response    = await this.post('programari/get_programari', {masina: this.Masina})
            this.Info       = response.Results
            this.loading    = false
           },
           modifica(item){
             this.$refs.programariDialog.set_checkbox_state(false)
             this.$refs.programariDialog.show_me(item.Id)
           },
            tableRowClassName({row}) {               
                if (row.AreRetur == true) {
                    return 'blinkable-warn';
                }
                return '';
            }
        },
        components: {
          'programari-dialog' : ProgramariDialog
        },
        mounted() {
            this.get_info()

        }
    }
</script>

<style lang="less" >
    .full-width {
        width: 100%;
    }
    .box-card {
        width: 480px;
    }
     .margin-bottom30 {
        margin-bottom:30px;
    }

    .blinkable-warn {
      background:white;
      color:black;
      animation: blinkWarn 1s infinite;       
    }

    @keyframes blinkWarn {
      from { background-color: white; }
      to { background-color: #E6A23C;color:white; }
    }
       
    
</style>