<template>
    <el-dialog title="Factura proforma" :visible.sync="showPopup"  :fullscreen="true">
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15">
                <el-col :md="4" :offset="20">
                    <el-button type="primary" @click="save('my-form')" > Salveaza </el-button>
                </el-col>
            </el-row>
            
            <el-row :gutter="15" >

                <el-col :md="12">
                    <el-form-item label="Client" prop="NumeClient">
                        <el-autocomplete class="full-width" v-model="selectedObject.NumeClient" placeholder="Cauta client" 
                        :fetch-suggestions="cautaClient" @select="handleSelectClient">                            
                        </el-autocomplete>
                    </el-form-item> 
                </el-col>
                <el-col :md='6'>
                <el-form-item label="Serii" prop="IdSerie">
                    <el-select class="full-width" v-model='selectedObject.IdSerie' filterable @change="handleSelectSerie"> 
                        <el-option v-for="item in Info.serii" :key="item.Id" :label="item.Serie" :value="item.Id"></el-option>
                    </el-select>
                </el-form-item>                    
                </el-col>
                <el-col :md='6'>
                    <el-form-item label='Numar'>
                        <el-input  class='full-width' v-model='selectedObject.Numar' :disabled="true"/>
                    </el-form-item>
                </el-col>
                <el-col :md='6'>
                    <el-form-item label='CIF client'>
                        <el-input  class='full-width' v-model='selectedObject.CifClient' :disabled="true"/>
                    </el-form-item>
                </el-col>
                <el-col :md='6'>
                    <el-form-item label='Reg. Com. client'>
                        <el-input  class='full-width' v-model='selectedObject.RegComClient' :disabled="true"/>
                    </el-form-item>
                </el-col>                
                <el-col :md='6'>
                    <el-form-item label='Status plata' prop="StatusPlata">
                        <el-select class='full-width' v-model='selectedObject.StatusPlata' >                            
                            <el-option label='platita' value='platita'></el-option>
                            <el-option label='neplatita' value='neplatita'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                 <el-col :md='6'>
                    <el-form-item label='Masina' prop="IdMasina">
                        <el-select v-model='selectedObject.IdMasina'  class='full-width'>
                            <el-option label='Fara' value='-1'></el-option>        
                            <el-option v-for='item in Info.masini' :key="'masini' + item.Id" :label='item.Numar' :value='item.Id'></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

            </el-row>
             <el-row :gutter="15" >                
                <el-col :md='24'>
                    <el-card shadow="never">
                        <div slot="header" class="clearfix"><span>Adauga Produs</span></div>
                        <el-row :gutter="15">
                            <el-col :md="5">Produs</el-col>
                            <el-col :md="5">Pret</el-col>
                            <el-col :md="5">Cantitate</el-col>
                            <el-col :md="5">Cota TVA</el-col>
                        </el-row>
                        <el-row :gutter="15">
                            <el-col :md="5">
                                <el-form-item >
                                     <el-autocomplete class="full-width" v-model="produs.NumeProdus" placeholder="Cauta produs" 
                                    :fetch-suggestions="cautaProdus" @select="handleSelectProdus">                            
                                    </el-autocomplete>
                                </el-form-item>
                            </el-col>
                            <el-col :md="5">
                                <el-form-item >
                                    <el-input class='full-width' v-model="produs.PretUnitar"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :md="5">
                                <el-form-item >
                                    <el-input class='full-width' v-model="produs.Cantitate"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :md="5">
                                <el-form-item >
                                    <el-select v-model='produs.CotaTva'  class='full-width'>
                                        <el-option label='9%' value='9'></el-option>        
                                        <el-option label='19%' value='19'></el-option>        
                                    </el-select>
                                </el-form-item> 
                            </el-col>
                            <el-col :md="4">
                                <el-button type="success" @click="adauga()">Adauga</el-button>                                
                            </el-col>
                        </el-row>

                        <el-table :data="selectedObject.Produse">
                            <el-table-column label="Nume produs" prop="NumeProdus"></el-table-column>
                            <el-table-column label="Pret unitar" prop="PretUnitar">
                                <template slot-scope="scope">
                                  <div v-if="!scope.row.Edited">{{scope.row.PretUnitar | format_money}}</div> 
                                  <el-input v-else class="full-width" v-model="scope.row.PretUnitar"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="Cantitate" prop="Cantitate">
                                 <template slot-scope="scope">
                                   <div v-if="!scope.row.Edited">{{scope.row.Cantitate | format_money}}</div> 
                                  <el-input v-else class="full-width" v-model="scope.row.Cantitate"></el-input>
                                </template>
                            </el-table-column> 
                            <el-table-column label="Cota TVA" prop="CotaTva">
                                 <template slot-scope="scope">
                                   <div v-if="!scope.row.Edited">{{scope.row.CotaTva | format_money}}</div> 
                                  <el-input v-else class="full-width" v-model="scope.row.CotaTva"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="Valoare" prop="Valoare">
                                <template slot-scope="scope">
                                   {{scope.row.Valoare | format_money}}
                                </template>
                            </el-table-column>
                            <el-table-column label="Valoare TVA" prop="ValoareTva"></el-table-column>

                            <el-table-column fixed="right" label="Actiuni">
                                <template fixed="right" slot-scope="scope" >
                                    <el-tooltip content="Modifica">
                                        <el-button v-if="!scope.row.Edited" size="mini" type="primary" icon="el-icon-edit"  circle @click="modifica(scope.row)" />
                                        <el-button v-else size="mini" type="primary" icon="el-icon-check"  circle @click="doneModifica(scope.row)" />
                                    </el-tooltip>
                                    <el-tooltip content="Sterge" >
                                        <el-button size="mini" type="danger" icon="el-icon-delete" circle @click="sterge(scope.row)" />
                                    </el-tooltip>
                                </template>
                            </el-table-column>                            
                        </el-table>
                        <el-row v-if="selectedObject.arata_total == true">
                            <el-col :md="6" :offset="18">
                                <table style="margin-top: 10px; width:70%">
                                    <tr>
                                        <th>Valoare totala</th>
                                        <td align="right"> {{selectedObject.ValoareFaraTva | format_money}} </td>
                                    </tr>
                                    <tr>
                                        <th>Valoare TVA</th>
                                        <td align="right"> {{selectedObject.ValoareTva | format_money}} </td>
                                    </tr>
                                </table>
                            </el-col>                            
                        </el-row>
                    </el-card>
                </el-col>
             </el-row>                               
            
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="resetForm('my-form')"           > Renunta  </el-button>
			<el-button type="primary" @click="save('my-form')" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Facturi_dialog",
        extends: BasePage,
        data () {
            return {
                showPopup: false,
                mode: 'add',
                selectedObjectTemplate: {
                    Numar: '' , IdSerie: '' , DataEmitere: '' , ValoareCuTva: '' , ValoareFaraTva: '', ValoareTva: '', IdClient: '', NumeClient: '' , CifClient: '' , 
                    RegComClient: '' , Furnizor: '' , CifFurnizor: '' , RegComFurnizor: '' , IdMasina: '-1' , StatusPlata: '' , Status: '-1' , EsteAnulata: '' , Produse: [], arata_total: false             
                },
                selectedObject: {},
                produsTemplate: { IdProdus: '', NumeProdus: '', Cantitate:'', PretUnitar:'', CotaTva:'', Valoare:'', ValoareTva:'',ValoareCuTva:'', ValoareFaraTva: ''},
                produs: {},
                Info:{
                    masini: [], serii: [] },
                rules: {
                    Numar: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdSerie: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DataEmitere: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    ValoareCuTva: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    ValoareFaraTva: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    NumeClient: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    CifClient: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    RegComClient: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Furnizor: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    CifFurnizor: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    RegComFurnizor: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdMasina: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    StatusPlata: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Status: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    EsteAnulata: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate));
                this.produs         = JSON.parse(JSON.stringify(this.produsTemplate))
                this.showPopup      = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("facturi/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;

                    this.selectedObject.Produse.forEach(p => {
                        p.ValoareTva = parseFloat(p.ValoareTva).toFixed(2)
                        console.log(p.ValoareTva)
                    })
                    
                }
            },
            async get_info(){
                var response = await this.post("facturi/get_info_for_dialog" );
                this.Info.masini    = response.masini; 
                this.Info.serii     = response.serii;           
            },

            async cautaClient(queryString, cb) {
                if(queryString != ''){ 
                    var response = await this.post("facturi/search_client", { queryString: queryString } )                   
                    cb(response.Results)                                                          
                } 
            },

            handleSelectClient(item) {                
                if(parseInt(item.id)<0) {                    
                    this.selectedObject.NumeClient=""
                } else {
                    var client                              = item.ClientComplet
                    this.selectedObject.IdClient            = client.Id
                    this.selectedObject.CifClient           = client.Cui
                    this.selectedObject.RegComClient        = client.RegCom
                    this.selectedObject.NumeClient          = client.Nume
                }                                       
            },

            async cautaProdus(queryString, cb){
                if(queryString != '')
                {
                    var response = await this.post("facturi/search_produs", {queryString: queryString})
                    cb(response.Results)
                }

            },

            handleSelectProdus(item) {                
                this.produs.IdProdus = item.id
            },

            async handleSelectSerie(){
                var response = await this.post("facturi/get_numar_serie", {id_serie: this.selectedObject.IdSerie})
                this.selectedObject.Numar = response.NumarNou;                
            },

            adauga(){                
                this.produs.Valoare         = parseFloat(this.produs.PretUnitar) * parseFloat(this.produs.Cantitate)
                //this.produs.ValoareTva      = parseFloat(this.produs.Valoare) * parseFloat(this.produs.CotaTva) / 100
                this.produs.ValoareFaraTva  = this.produs.Valoare / 1.19                
                this.produs.ValoareTva      = (this.produs.Valoare - this.produs.ValoareFaraTva).toFixed(2)
                this.produs.ValoareCuTva    = this.produs.Valoare + this.produs.ValoareTva
                this.selectedObject.Produse.push(this.produs)
                this.produs = JSON.parse(JSON.stringify(this.produsTemplate))
                this.totalProduse();
            },

            modifica(item){
                item.Edited = true;
                this.selectedObject.Produse.push({})
                this.selectedObject.Produse.pop()
                this.$forceUpdate()                
            },

            doneModifica(item){
                item.Edited = false;
                this.selectedObject.Produse.push({})
                this.selectedObject.Produse.pop()
                this.selectedObject.Produse.forEach(el => {
                    el.Valoare      = el.PretUnitar * el.Cantitate
                    let ValFaraTva = el.Valoare / 1.19
                    el.ValoareTva   = (el.Valoare - ValFaraTva).toFixed(2)
                    el.ValoareCuTva = el.Valoare + el.ValoareTva
                })
                this.totalProduse()
            },

            async sterge(item){
                var confirm =  await this.$confirm(`Sunteti sigur ?`, 'Warning')
                if( confirm ){
                    this.selectedObject.Produse.pop()
                    if(this.selectedObject.Produse.length>0){
                        this.totalProduse()
                    } else this.selectedObject.arata_total = false                               
                }                                                
            },

            totalProduse(){                                
                var TotalValoareCuTva   = []
                var TotalValoareTva     = []
                var TotalValoareFaraTva = []
                this.selectedObject.Produse.forEach(el => {
                    TotalValoareFaraTva.push(parseFloat(el.Valoare))
                    TotalValoareCuTva.push(parseFloat(el.ValoareCuTva))
                    TotalValoareTva.push(parseFloat(el.ValoareTva))
                })               
                this.selectedObject.ValoareCuTva    = TotalValoareCuTva.reduce((a, b) => a + b)
                this.selectedObject.ValoareFaraTva  = TotalValoareFaraTva.reduce((a, b) => a + b)
                this.selectedObject.ValoareTva      = TotalValoareTva.reduce((a, b) => a + b)
                this.selectedObject.arata_total     = true                
                this.$forceUpdate()                
            },  

            save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("facturi/save", { mode: this.mode, object: this.selectedObject } );
                        this.resetForm()
                        this.$emit("save");
                    } else console.log('eroare validare formular!')
                });
            },
            resetForm() {
				this.$refs['my-form'].resetFields()
				this.showPopup = false
			}
        },
        mounted: function() {
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

    .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }

</style>