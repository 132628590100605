<template>
    <base-page>
        <titlu-pagina Titlu="Auto Serv" @on_title_clicked="reset" :exportExcel="true" @onExportClick="exportExcel"/>
        <el-card style='margin:5px 0px 5px 0px'>
            <div slot="header" class="clearfix" style="text-align:center" >
                <strong> Filtre </strong>
            </div>
            <div class="filtre" style="text-align:center; ">
                <el-form :inline="true" @submit.prevent.native='refresh_info()'>
                    <el-row :gutter="20">
                        
                        <el-form-item label='Masina'>                           
                            <SelectMasina 
                                class="full-width input-group-input" 
                                v-model="numarMasina" 
                                :NumarMasina="numarMasina" 
                                :IdMasina.sync="Filters.IdMasina" 
                                @selectie="select_masina" />
                        </el-form-item>
                        <el-button type='primary' native-type='submit' @click='refresh_info()'> Aplica </el-button>
                        <el-button  @click='reset'> Reset </el-button>
                       
                    </el-row>
                </el-form>
            </div>
        </el-card>

        <el-table :data="Rezultate" v-loading="loading" @expand-change="expand" :row-class-name="tableRowClassName">

            <el-table-column type="expand" >       
                <template slot-scope="props">     
                    <Remindere ref="remindereCard" :Masina="props.row"/>                      
                    <Programari ref="programariCard" :Masina="props.row"/>
                    <Checklists :Masina="props.row"/>
                    <Oferte @amanata="refresh_remindere()" @generarecomadafurnizor="refresh_comenzi_furnizori()" @trimisa_la_client="refresh_remindere()" :Masina="props.row"/>  
                    <ComenziFurnizori @programaresalvata="refresh_programari()" @deviz_creat="refresh_devize" ref="comenziFurnizoriCard" :Masina="props.row"/>
                    <DevizeCard @devizsalvat='refresh_devize()' @facturaGenerata='refresh_facturi()' ref="devizeCard" :Masina="props.row"/>
                    <FacturiCard @facturasalvata='refresh_facturi()' ref="facturiCard" :Masina="props.row"/>
                    <InspectieFinalaCard ref="InspectieFinalaCard" :Masina="props.row"/>
                    <FiseCard ref="FiseCard" :Masina="props.row" />
                    <HotelAnvelopeCard ref="HotelAnvelopeCard" :Masina="props.row"/>
                </template>
            </el-table-column>

            <el-table-column prop='Numar' label='Masina' width="300px">
                <template slot-scope="scope">
                    <span style="font-size:30px">{{scope.row.Numar}}</span> 
                    <el-badge style="margin-right:10px" v-if="scope.row.IsModified == '1'" is-dot class="item" type="success"></el-badge>                  
                    <el-badge v-if="scope.row.AreReturProduse == '1'" is-dot class="item" type="warning"></el-badge>                        
                </template>   
            </el-table-column> 
            
            <el-table-column prop='Info' width="700px">
                <template slot-scope="scope">
                    <span style="font-size:20px; color: #8492a6;">{{scope.row.Info}}</span> 
                </template>
            </el-table-column>

            <el-table-column width="50px">
                <template slot-scope="scope">
                    <el-tooltip content="Observatii" placement="left">
                        <MasiniObservatiiList :Masina="scope.row"/>
                    </el-tooltip>                    
                </template>
            </el-table-column>

            <el-table-column >
                <template slot-scope="scope" >                
                    <el-tooltip content="Sterge masina" placement="right">
                        <el-button type="danger" plain icon="el-icon-delete" circle @click="sterge_masina(scope.row)" />
                    </el-tooltip>
                    </template>
            </el-table-column>   

        </el-table>

        <el-pagination @size-change="refresh_info" @current-change="refresh_info" :page-size.sync="PaginationInfo.PerPage" :current-page.sync="PaginationInfo.Page" :total="PaginationInfo.RowCount" layout="pager" />

    </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import TitluPagina from '@/widgets/TitluPagina';
import Oferte from '@/components/Oferte.vue';
import Programari from '@/components/Programari.vue';
import Remindere from '@/components/Remindere.vue';
import ComenziFurnizori from '@/components/comenzi_furnizori/ComenziFurnizoriCard'
import Checklists from '@/components/checklists/ChecklistCard'
import DevizeCard from '@/components/devize/DevizeCard.vue'
import FiseCard from '@/components/fise/FiseCard.vue'
import FacturiCard from '@/components/facturi/FacturiCard.vue'
import InspectieFinalaCard from '@/components/inspectii_finale/InspectieFinalaCard.vue'
import HotelAnvelopeCard from '@/components/hotel_anvelope/HotelAnvelopeCard.vue'
import SelectMasina from '@/components/SelectMasina'
import MasiniObservatiiList from '@/components/masini_observatii/Masini_observatii_list.vue';

export default {
    name: "crm",
    extends: BasePage,
    components: {
        'base-page': BasePage,
        'titlu-pagina': TitluPagina,
        Oferte,
        Programari,
        Remindere,
        ComenziFurnizori,
        Checklists,
        DevizeCard,
        FiseCard,
        FacturiCard,
        InspectieFinalaCard,
        HotelAnvelopeCard,
        SelectMasina,
        MasiniObservatiiList
    },
    data () {
        return {
            Results: [],
            Rezultate: [],
            base_url: '',
            Info: {masini: []},                
            Filters: { IdMasina: '-1'},                
            OrderBy: { },
            PaginationInfo: { Page: 1, PerPage: 50, RowCount: 0, PageSizes: [10, 25, 50, 100, 200] },
            loading: true,
            numarMasina:''
        }
    },
    methods: {
        async sterge_masina(item){            
            this.$confirm(`Sunteti sigur ?`, 'Atentie, masina ' +item.Numar+ ' va fi stearsa!', {type: 'warning'})               
                .then(async() => {               
                    let response = await this.post('CRM/sterge_masina', {id_masina:item.Id})
                    this.$message({type: 'info', message: response.mesaj});
                    this.refresh_info();})
                .catch(() => {
                    this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                });              
        },
        async get_info(){
            var response     = await this.post("CRM/get_info" );
            this.Info.masini = response.masini;           
            this.refresh_info();
        },
        async refresh_info(){            
            var response        = await this.post("CRM/index", { Filters: this.Filters, OrderBy: this.OrderBy, PaginationInfo: this.PaginationInfo } );
            this.Rezultate      = response.Rezultate;
            this.PaginationInfo = response.PaginationInfo;                        
            this.loading        = false;
        },
        refresh_remindere() {
          this.$refs.remindereCard.get_info()
        },
        refresh_comenzi_furnizori() {
          this.$refs.comenziFurnizoriCard.refresh_info() 
        },
        refresh_programari() {
          this.$refs.programariCard.get_info()
        },
        refresh_devize(){
            this.$refs.devizeCard.refresh_info()
        },
        refresh_facturi(){
            this.$refs.facturiCard.refresh_info()
        },
        reset(){
            this.Filters = { IdMasina: '-1' };   
            this.numarMasina = ''             
            this.refresh_info();
        },      
        async expand(row)  {
            await this.post("CRM/update_masina", {id_masina: row.Id})            
        },
        tableRowClassName({row}) {               
            if (row.AreDevizDeschis == true) {
                return 'blinkable';
            }
            if (row.AreCerereDeOferta)
                return 'blinkable-yellow'

            return '';
        },
        select_masina(item){
            this.numarMasina = item.Numar
        },
        exportExcel(){            
           window.open( `${this.base_url}/CRM/export_excel/`);        
        },
    },
    mounted(){
        this.base_url = settings.BASE_URL;
        this.get_info();
    }

};
</script>

<style >

    .top50{
        margin-top: 20px;
    }

    .filtre{
        .el-input-number
        {
            width: 100% !important;
        }
    }

    .blinkable {
      background:white;
      color:black;
      animation: blink 1s infinite;       
    }

    .blinkable-yellow{
        background:white;
        color:black;
        animation: blinkYellow 1s infinite;    
    }

    @keyframes blink {
      from { background-color: white; }
      to { background-color: red;color:white; }
    }

    @keyframes blinkYellow {
      from { background-color: white; }
      to { background-color: #f0b737;color:white; }
    }

</style>
