<template>
        <el-dialog title="Remindere" :visible.sync="showPopup" class="my-dialog-class" append-to-body>
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                <el-col :md='8'>
                  <el-form-item label='Data executie' prop="DataExecutie">    
                    <el-date-picker  class='full-width' v-model='selectedObject.DataExecutie' type='datetime' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
                  </el-form-item>
                </el-col>                         
                <el-col :md='8'>
                  <el-form-item label='Mesaj' prop="Mesaj">
                      <el-input  class='full-width' v-model='selectedObject.Mesaj' />
                  </el-form-item>
                </el-col>                         
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
          <el-button @click="resetForm('my-form')"           > Renunta  </el-button>
		      <el-button type="primary" @click="save('my-form')" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Remindere_dialog",
        extends: BasePage,
        data () {
            return {
                showPopup: false,
                idMasina: '',
                mode: 'add',
                selectedObject: {DataExecutie:'',Mesaj: '' , },
                rules: {
                    Mesaj: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    DataExecutie: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                this.idMasina = id;
                if( id > -1 )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("remindere/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info() {
                var response = await this.post("remindere/get_info_for_dialog" )
            },
            save: function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid) {                        
                        this.$emit('salveaza', this.selectedObject)
                        this.resetForm()
                    } else console.log('eroare validare formular!')
                });
            },
            resetForm() {
				this.$refs['my-form'].resetFields()
				this.showPopup = false
			}
        },
        mounted: function() {
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>