<template>   
    <div>        
        <el-popover placement="right" width="400" trigger="click" @show="refresh_info()"> 
            <el-button type="warning" size="mini" @click="show_dialog()">Adauga</el-button>
            <el-table :data="Results" v-loading="loading">
                <el-table-column prop='Mesaj' label='Obs.' width="200"></el-table-column>
                <el-table-column label='Data' width="100">
                    <template slot-scope='scope'>
                        {{ scope.row.DataAdaugare | format-date }}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="Actiuni" >
                    <template slot-scope="scope" >                       
                        <el-tooltip content="Sterge" >
                            <el-button type="danger" icon="el-icon-delete" mini circle @click="delete_item(scope.row.Id)" />
                        </el-tooltip>
                    </template>
                </el-table-column>            
            </el-table>
            <el-button :type="Masina.AreObservatii ? 'danger' : 'info'" icon="el-icon-chat-line-square" :plain="Masina.AreObservatii ? false : true" circle slot="reference"> </el-button>        
        </el-popover>
        <Masini-observatii-dialog ref='dlg' @save="refresh_info()" :idMasina="Masina.Id"/>
    </div>    
</template>

<script>
    import BasePage from '@/pages/BasePage';
    import Masini_observatii_dialog from '@/components/masini_observatii/Masini_observatii_dialog.vue';

    export default {
        name: "serii",
        extends: BasePage,
        components: {
            'Masini-observatii-dialog': Masini_observatii_dialog,
        },
        props:['Masina'],
        data () {
            return {
                Results: [],
                loading:true
            }
        },
        methods: {
        
            async refresh_info(){
                this.loading    = true;
                var response    = await this.post("observatii_masini/get_observatii_masina", { IdMasina: this.Masina.Id } );
                this.Results    = response.Results;  
                this.loading    = false;          
            },       
            async delete_item( id ){            
                this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                    type: 'warning'
                    }).then(async() => {
                        await this.post("observatii_masini/delete_item", { id: id } );
                        this.refresh_info(); 
                        this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                        }).catch(() => {
                            this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                            });
            },
            show_dialog(id){
                this.$refs.dlg.show_me(id);
            }
        },
        mounted(){
            // this.refresh_info();
        }
    };
</script>

<style lang="less" scoped>
   

</style>
