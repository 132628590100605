
<template>
    <el-card class="full-width margin-bottom30">  
        <div slot="header" class="clearfix">
            <span>Devize</span>
        </div>      
        <el-table :data="Results" v-loading="loading">

            <el-table-column label='Data intrare'>
                <template slot-scope='scope'>
                    {{ scope.row.DataIntrare | momentformat('dd.MM.YYYY') }}
                </template>
            </el-table-column>
            <el-table-column label='Data inchidere'>
                <template slot-scope='scope'>
                    {{ scope.row.DataInchidere | momentformat('dd.MM.YYYY') }}
                </template>
            </el-table-column>
            <el-table-column prop='Status' label='Status'></el-table-column>
            <el-table-column prop='Total' label='Total'>
                <template slot-scope="scope">            
                    {{scope.row.Total | format_money}} lei
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="250px" >
                <template slot-scope="scope" >

                    <el-tooltip v-if="$has_right('modificare-deviz')" content="Vizualizare">
                        <el-button type="primary" plain size="small" icon="el-icon-view" @click="show_dialog(scope.row.Id)" />
                    </el-tooltip>

                    <el-tooltip content="Generare factura" v-if="scope.row.Status == 'deschis'">
                        <el-button type="success" plain size="small" icon="el-icon-document" :disabled="(parseFloat(scope.row.Total) == 0)" @click="genereaza_factura(scope.row)" />
                    </el-tooltip>                    

                    <el-tooltip content="Vizualizare produse">
                        <el-button type="info" plain size="small" icon="el-icon-menu" @click="preview_produse(scope.row)" />
                    </el-tooltip>

                    <el-tooltip content="Inchidere deviz" v-if="scope.row.Status == 'deschis' && parseFloat(scope.row.Total) == 0">
                        <el-button type="warning" plain size="small" icon="el-icon-document-checked" @click="inchidere_deviz(scope.row)" />
                    </el-tooltip> 

                </template>
            </el-table-column>
        </el-table>
        <Devize-dialog ref='dlg' @save="salvare_deviz()" />        
        <Deviz-produse-pdf ref="deviz-produse-pdf"/>
        <validare-deviz ref="validare" @save="refresh_info" />
    </el-card>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from '@/pages/BasePage'
import Devize_dialog from '@/pages/devize/Devize_dialog.vue';
import Deviz_produse_pdf from '@/pages/devize/Deviz_produse_pdf.vue';
import Factura_pdf from '@/pages/facturi/Factura_pdf.vue';
import Validare_deviz from '@/components/devize/ValidareDeviz';

export default {
    name: "DEvizeCard",
    extends: BasePage,
    props:['Masina'],
    components: {        
        'Devize-dialog': Devize_dialog,
        'Deviz-produse-pdf': Deviz_produse_pdf,        
        'factura-dlg': Factura_pdf,
        'validare-deviz': Validare_deviz,
    },
    data () {
        return {
            Results: [],        
            loading : true                         
        }
    },
    methods: {
        async refresh_info(){
            var response = await this.post("devize/get_devize", { idMasina:this.Masina.Id } );
            this.Results = response.Results;
            this.loading = false
        },
              
        async genereaza_factura(item){
          this.$confirm('Sunteti sigur ca doriti generarea facturii?', 'Atentionare', {
            confirmButtonText: 'OK',
            cancelButtonText: 'Anulare',
            type: 'warning'
            }).then(async () => {
                var response = await this.post("facturi/generare_factura", { id_deviz: item.Id } );
                var idFactura = response.IdFactura
                // this.$refs.factura_pdf.genereaza(idFactura)
                this.refresh_info()
                this.$emit('facturaGenerata')
            }).catch(() => { 
                //fac nimic
            })
        },

        salvare_deviz(){
            this.$emit['devizsalvat']
            //this.refresh_info()
        },

        preview_factura (deviz) {
          this.$refs.factura_pdf.genereaza(deviz.IdFactura)
        },

        preview_produse(item){
            this.$refs['deviz-produse-pdf'].show(item.Id)
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        },
        inchidere_deviz(row){
            this.$refs.validare.get_info(row)
        }
    },
    mounted(){
        this.refresh_info();
    }
};
</script>

<style lang="less">

    .margin-bottom30 {
      margin-bottom:30px;
    }

</style>
