<template>
    <el-card class="full-width margin-bottom30"> 
        <div slot="header" class="clearfix">
            <span>Oferte</span>
            <span style="float: right;">
              <el-button type="success" plain size="small" @click="ofertat()"> Ofertat </el-button>
              <el-button style="margin-left:10px" type="primary" plain size="small" icon="el-icon-plus" round @click="adauga()"></el-button>
            </span>                       
            <span></span>                       
        </div>
        <el-table class="full-width" :data="Oferte" v-loading="loading">
          <el-table-column label="Data creare" prop="DataCreare"></el-table-column>
          <el-table-column label="Status" prop="Status"></el-table-column>
          <el-table-column label="Observatii" prop="Observatii"></el-table-column>
          <el-table-column label="Actiuni">

            <template slot-scope="scope">
               <el-tooltip class="item" effect="dark" content="Trimite la client" v-if="scope.row.Status == 'in_lucru'">
                  <el-button type="info" plain size="small" icon="el-icon-share" @click="trimite_la_client(scope.row)"></el-button>
                </el-tooltip>
                 <el-tooltip class="item" effect="dark" content="Modifica"  v-if="scope.row.Status == 'in_lucru'">
                  <el-button type="primary" plain size="small" icon="el-icon-edit" @click="modifica(scope.row)"></el-button>
                </el-tooltip>
                 <el-tooltip class="item" effect="dark" content="Download PDF" >
                  <el-button type="success" plain size="small" icon="el-icon-download" @click="download_pdf(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Sterge"  v-if="scope.row.Status == 'in_lucru'">
                  <el-button type="danger" plain size="small" icon="el-icon-delete" @click="sterge(scope.row)"></el-button>
                </el-tooltip>

                <el-tooltip class="item" effect="dark" content="Aproba"  v-if="scope.row.Status == 'trimisa' || scope.row.Status == 'amanata'">
                  <el-button type="success" plain size="small" icon="el-icon-check" @click="aproba(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Respinge"  v-if="scope.row.Status == 'trimisa' || scope.row.Status == 'amanata'">
                  <el-button type="warning" plain size="small" icon="el-icon-close" @click="respinge(scope.row)"></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Adauga reminder"  v-if="scope.row.Status == 'trimisa' || scope.row.Status == 'amanata'">
                  <el-button type="primary" plain size="small" icon="el-icon-time" @click="show_amanare(scope.row)"></el-button>
                </el-tooltip>

                <el-tooltip class="item" effect="dark" content="Anuleaza respingere"  v-show="scope.row.Status == 'respinsa'">
                  <el-button type="primary" plain size="small" icon="el-icon-refresh-right" @click="anuleaza_respingere(scope.row)"></el-button>
                </el-tooltip>

                <el-tooltip class="item" effect="dark" content="Genereaza comanda"  v-if="scope.row.Status == 'aprobata'">
                  <el-button type="success" plain size="small" icon="el-icon-success" @click="genereaza_comanda(scope.row)"></el-button>
                </el-tooltip>
            </template>
            
          </el-table-column>
        </el-table>
        <el-dialog title="Amana oferta" :visible.sync="Amanare.ArataAmanare" width="30%" append-to-body >
          <p>Adauga reminder:</p>
          <el-date-picker  class='full-width' v-model='Amanare.DataNouaExecutie' type='datetime' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
          <p>Mesaj reminder:</p>
          <el-input class="full-width" v-model='Amanare.Mesaj'></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="Amanare.ArataAmanare = false">Anuleaza</el-button>
            <el-button type="primary" @click="amana()">Confirma</el-button>
          </span>
        </el-dialog>
        <pop-adaugare-produse-comanda-furnizor @comandaGenerata="comanda_furnizor_generata()" ref="popAdaugareProduse"></pop-adaugare-produse-comanda-furnizor>
        <oferte-dialog ref='dlg' :MasinaId="Masina.Id" @save="refresh_info()" />
    </el-card>
</template>

<script>
    import settings from "@/backend/LocalSettings"
    import BasePage from '@/pages/BasePage'
    import PopupAdaugareProduse from '@/components/comenzi_furnizori/PopupAdaugareProduse'
    import Oferte_dialog from '@/pages/oferte/Oferte_dialog.vue';  
    import moment from 'moment';

    export default {
        name: "Oferte",
        extends: BasePage,
        props:['Masina'],
        data(){
          return {
              Oferte: [],
              Amanare: {DataNouaExecutie:'',IdOferta:'',Mesaj:'',ArataAmanare:false},
              loading : true
          }        
        },
        methods: {
			async refresh_info() {
				var response  = await this.post('oferte/get_oferte_masina',{masina:this.Masina})
				this.Oferte   = response.Results;
				this.loading  = false
			},
			genereaza_comanda(oferta) {
				this.$refs.popAdaugareProduse.show_me(oferta.Id)
			},
			adauga() {
			//  console.log(this.Masina.Id);
			//  return
				this.$refs.dlg.show_me(null,this.Masina.Id);
			},
			async ofertat(){
				this.$confirm('Confirmare oferta trimisa', 'Atentionare', {
					confirmButtonText: 'OK',
					cancelButtonText: 'Anulare',
					type: 'warning'
				}).then(async () => {

					var response = await this.post('oferte/ofertat',{idMasina:this.Masina.Id})

					if(!response.Error)
						this.$message({type: 'success',message: 'Modificare efectuata cu succes!'})
					else
						this.$message({type: 'warning',message: 'Modificarea nu a putut fi efectuata!'})

					this.refresh_info()
				})
			},
			async trimite_la_client (oferta) {
				this.$confirm('Oferta va fi marcata "Trimisa la client". Continuati?', 'Atentionare', {
					confirmButtonText: 'OK',
					cancelButtonText: 'Anulare',
					type: 'warning'
				}).then(async () => {
					var response = await this.post('oferte/trimite_la_client',{Oferta:oferta})
					this.$message({type: 'success',message: 'Oferta a fost modificata!'})
					this.$emit('trimisa_la_client');
					this.refresh_info()
				}).catch(() => {
				// fac nimic
				})
			},
			modifica (oferta) {             
				this.$refs.dlg.show_me(oferta.Id, this.Masina.Id)
			},

			download_pdf(item) {             
				window.open("/#/oferta_pdf/" + item.Id, '_blank');
			},

			async sterge (oferta) {
				this.$confirm('Sunteti sigur ca doriti stergerea ofertei?', 'Atentionare', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Anulare',
				type: 'warning'
				}).then(async () => {
				var response = await this.post('oferte/sterge',{idOferta:oferta.Id})
				this.$message({type: 'success',message: 'Oferta a fost stearsa!'})
				this.refresh_info() 
				}).catch(() => {
				// fac nimic
				})
			},
			aproba(oferta) {
			this.$confirm('Sunteti sigur ca doriti aprobarea ofertei?', 'Atentionare', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Anulare',
				type: 'warning'
			}).then(async () => {
				var response = await this.post('oferte/aproba',{idOferta:oferta.Id})
				this.$message({type: 'success',message: 'Oferta a fost aprobata!'})
				this.refresh_info() 
			}).catch(() => {
				// fac nimic
			})
			},
			respinge (oferta) {
				this.$confirm('Sunteti sigur ca doriti respingerea ofertei?', 'Atentionare', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Anulare',
				type: 'warning'
			}).then(async () => {
				var response = await this.post('oferte/respinge',{idOferta:oferta.Id})
				this.$message({type: 'success',message: 'Oferta a fost respinsa!'})
				this.refresh_info() 
			}).catch(() => {
				// fac nimic
			})
			},
			anuleaza_respingere(oferta) {
				this.$confirm('Sunteti sigur ca doriti anularea respingerii?', 'Atentionare', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Anulare',
				type: 'warning'
					}).then(async () => {
						var response = await this.post('oferte/anuleaza_respingere',{idOferta:oferta.Id})
						this.$message({type: 'success',message: 'Oferta a fost reactivata!'})
						this.refresh_info()
					}).catch(() => {
					// fac nimic
					})
			},
			show_amanare(oferta) {
				this.Amanare.ArataAmanare    	= true
				this.Amanare.IdOferta        	= oferta.Id
				let newTime                   	= moment().add(1, 'days').format("YYYY-MM-DD 14:00:00")
				this.Amanare.DataNouaExecutie	= newTime;

			},
			comanda_furnizor_generata () {
				this.refresh_info()
				this.$emit('generarecomadafurnizor')
			},
			async amana() {
				var response = await this.post('oferte/amana',{amanare:this.Amanare})
				this.Amanare.ArataAmanare = false
				this.refresh_info()
				this.$emit('amanata');
			}
        },
        components: {
          'pop-adaugare-produse-comanda-furnizor': PopupAdaugareProduse,
          'oferte-dialog' : Oferte_dialog
        },
        mounted: function(){
            this.refresh_info();
        }
    }
</script>

<style lang="less">
    .full-width {
        width: 100%;
    }

    .margin-bottom30 {
      margin-bottom:30px;
    }
       
</style>