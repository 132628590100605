
<template>
    <el-card class="full-width margin-bottom30">  
        <div slot="header" class="clearfix">
            <span>Tire Hotel</span>
        </div>      
        <el-table :data="Results" v-loading="loading">
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-table :data="scope.row.Produse">
                        <el-table-column label="Tip" prop="Tip" width="100"></el-table-column>
                        <el-table-column label="Cantitate" prop="Cantitate" width="100"></el-table-column>                                                
                        <el-table-column label="Info" prop="Info"></el-table-column>
                    </el-table>
                </template>
            </el-table-column>
            <el-table-column label='Locatie' prop="Locatie" width="150px">
                <template slot-scope='scope'>
                    <div v-if="scope.row.Locatie != ''">{{ scope.row.Locatie }}</div>
                    <div v-else>nesetata</div>
                </template>
            </el-table-column>
            <el-table-column label='Data depozitarii'>
                <template slot-scope='scope'>
                    <div v-if="scope.row.DataDepozitarii != null && scope.row.DataDepozitarii != '0000-00-00 00:00:00'">{{ scope.row.DataDepozitarii | momentformat('dd.MM.YYYY') }}</div>
                    <div v-else>-</div>
                </template>
            </el-table-column>
            <el-table-column label='Data platii'>
                <template slot-scope='scope'>
                    <div v-if="scope.row.DataPlatii != null && scope.row.DataPlatii != '0000-00-00 00:00:00'">{{ scope.row.DataPlatii | momentformat('dd.MM.YYYY') }}</div>
                    <div v-else>-</div>
                </template>
            </el-table-column>
            <el-table-column label="Durata luni (zile)" prop="DurataLuni" width="150"></el-table-column>
            <el-table-column prop='Valoare' label='Valoare'>
                <template slot-scope="scope">            
                    {{scope.row.Valoare | format_money}} lei
                </template>
            </el-table-column>
            <el-table-column label="Status" prop="Status"></el-table-column>
            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >

                    <el-tooltip content="Editeaza">
                        <el-button type="primary" plain size="small" icon="el-icon-edit" @click="show_dialog_gestiune(scope.row.Id)" />
                    </el-tooltip>
                    <el-tooltip content="Vizualizare">
                        <el-button type="primary" plain size="small" icon="el-icon-view" @click="show_pdf(scope.row.Id)" />
                    </el-tooltip>
                    <el-tooltip content="Sterge" >
                        <el-button type="danger" plain size="small" icon="el-icon-delete" @click="delete_item(scope.row.Id)" />
                    </el-tooltip>
                   
                </template>
            </el-table-column>
        </el-table>  

        <hotel-anvelope-dialog ref="hotelAnvelope" @save="refresh_info"/>
        <HotelAnvelopePdf ref="pdf" />
    </el-card>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from '@/pages/BasePage'
import Hotel_anvelope_dialog from '@/components/hotel_anvelope/HotelAnvelope_dialog.vue';
import HotelAnvelopePdf from '@/components/hotel_anvelope/HotelAnvelopePdf.vue';


export default {
    name: "HotelAnvelopeCard",
    extends: BasePage,
    props:['Masina'],
    components: {        
       'hotel-anvelope-dialog':Hotel_anvelope_dialog,
       HotelAnvelopePdf
    },
    data () {
        return {
            Results: [],        
            loading : true                         
        }
    },
    methods: {
        async refresh_info(){
            this.loading = true
            var response = await this.post("hotel_anvelope/get_intrari_hotel", { idMasina:this.Masina.Id } );
            this.Results = response.Results;
            this.loading = false
        },
        
        show_dialog_gestiune(id){
            this.$refs.hotelAnvelope.show_me(id)
        },
        inchidere_deviz(row){
            this.$refs.validare.get_info(row)
        },
        show_pdf(id){
            this.$refs.pdf.show(id)
        },
        async delete_item( id ){            
            this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                type: 'warning'
                }).then(async() => {
                    await this.post("hotel_anvelope/delete_item", { id: id } );
                    this.refresh_info(); 
                    this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                    }).catch(() => {
                        this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                        });
        },
    },
    mounted(){
        this.refresh_info();
    }
};
</script>

<style lang="less">

    .margin-bottom30 {
      margin-bottom:30px;
    }

</style>
