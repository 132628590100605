<template>
  <el-card class="full-width margin-bottom30"> 
    <div slot="header" class="clearfix">
        <span>Check 360</span>
    </div>
    <el-table class="full-width" :data="Results" v-loading="loading">
      <el-table-column label="Comanda">
        <template slot-scope="scope">
          Checklist {{scope.row.Id}}
        </template>
      </el-table-column>
      <el-table-column label="Data adaugare" prop="Data"></el-table-column>
      <el-table-column label="Actiuni">
        <template slot-scope="scope">    

          <el-tooltip class="item" effect="dark" content="Vizualizeaza" >
            <el-button type="success" plain size="small" icon="el-icon-view" @click="vizualizeaza(scope.row)"></el-button>
          </el-tooltip>

          <el-tooltip class="item" effect="dark" content="Download PDF" >
            <el-button type="info" plain size="small" icon="el-icon-download" @click="download_pdf(scope.row)"></el-button>
          </el-tooltip>

        </template>
      </el-table-column>
    </el-table>
    <checklist-view ref="checklistView"></checklist-view>
  </el-card>
</template>

<script>
    import settings from "@/backend/LocalSettings"
    import BasePage from '@/pages/BasePage'
    import ChecklistView from '@/pages/mecanic/Check360_dialog'

    export default {
        name: "Checklists",
        extends: BasePage,
        props:['Masina'],
        data(){
            return {
                Results: [],
                loading : true
            }        
        },
        methods: {
           async refresh_info() {
              var response = await this.post('Checklist/get_checklists_for_car',{idMasina:this.Masina.Id})
              this.Results = response.Results
              this.loading = false
           },
           vizualizeaza(checklist) { 
             this.$refs.checklistView.show_me_for_edit(checklist.Id)
           },
           download_pdf(item) {             
             window.open("/#/checklist_pdf/" + item.Id, '_blank');
           },
        },
        components: {
          'checklist-view' : ChecklistView
        },
        mounted: function(){
            this.refresh_info();
        }
    }
</script>

<style lang="less">
    .full-width {
        width: 100%;
    }

    .margin-bottom30 {
      margin-bottom:30px;
    }
       
</style>