<template>
    <!-- <el-dialog title="Factura" :visible.sync="showPopup" class="my-dialog-class" append-to-body> -->
        <object :data="Constants.ServerUrl + 'facturi/show_factura_pdf/' + IdFactura" type="application/pdf" width="100%" height="1000">
            <p><a :href="Constants.ServerUrl + 'facturi/show_factura_pdf/' + IdFactura"></a></p>
        </object>
    <!-- </el-dialog> -->
</template>

<script>
import settings from "@/backend/LocalSettings"
import BasePage from '@/pages/BasePage' 
import Constants from '@/backend/Constants'
export default {
    name: "Factura_pdf",
    extends: BasePage,
    data(){
        return {
            showPopup: false,           
            Constants: Constants,
            IdFactura: 0
        }
    },
    methods: {
        genereaza() {  
            // console.log(idFactura)
            this.IdFactura   = this.$route.params.factura
            this.showPopup  = true 
        }
    },
    mounted() {
        this.genereaza()
    }
}
</script>