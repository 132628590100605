<template>
    <el-dialog title="Devize" :visible.sync="showPopup" class="my-dialog-class" >
      <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
        <el-row :gutter="15" >
          <el-col :md='8'>
              <el-form-item label='Data intrare' prop="DataIntrare">
                  <el-date-picker disabled  class='full-width' v-model='selectedObject.DataIntrare' type='datetime' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
              </el-form-item>
          </el-col>
          <el-col :md='8'>
              <el-form-item label='Total' prop="Total">
                  <el-input disabled class='full-width' v-model='selectedObject.Total' ></el-input>
              </el-form-item>
          </el-col>
          <el-col :md='8'>
            <el-form-item label='Masina' prop="IdMasina">
              <el-select disabled v-model='selectedObject.IdMasina'  class='full-width'>
                <el-option label='Fara' value='-1'></el-option>      
                <el-option v-for='item in Info.masini' :key="'masini' + item.Id" :label='item.Numar' :value='item.Id'></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="24">
            <h6>Produse</h6>
            <el-table class="full-width" :data="selectedObject.Produse" v-loading="loading" show-summary :summary-method="getSummaries">
              <el-table-column label="Nume produs" prop="NumeProdus"></el-table-column>
              <!-- <el-table-column label="Cod saga" prop="CodSaga"></el-table-column>
              <el-table-column label="CodProdus" prop="CodProdus"></el-table-column>   -->
              <el-table-column label="Cantitate" prop="Cantitate"></el-table-column>
              <el-table-column label="Pret unitar" prop="PretUnitar">
              </el-table-column>
              <el-table-column label="Valoare" prop="Valoare">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer" >
        <el-button type="info" @click="showPopup = false"          > OK  </el-button>
        <!-- <el-button type="primary" @click="save('my-form')" > Salveaza </el-button> -->
      </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Devize_dialog",
        extends: BasePage,
        data () {
            return {
                showPopup: false,
                mode: 'add',
                loading: false,
                selectedObject: {
                    DataIntrare: '' , DataInchidere: '' , Status: '-1' , Total: '' , IdMasina: '-1' ,Produse:[]},
                Info:{
                    masini: [],                 },
                rules: {
                    DataIntrare: [ { required: true, message: 'Campul este obligatoriu' } ],  
                    Total: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    IdMasina: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("devize/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("devize/get_info_for_dialog" );
                this.Info.masini = response.masini;            
            },
            save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("devize/save", { mode: this.mode, object: this.selectedObject } );
                        this.resetForm()
                        this.$emit("save");
                    } else console.log('eroare validare formular!')
                });
            },
            resetForm() {
				this.$refs['my-form'].resetFields()
				this.showPopup = false
            },
            
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = 'Total';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                        return prev + curr;
                    } else {
                        return prev;
                    }
                    }, 0);
                } else {
                    sums[index] = 'N/A';
                }
                });

                return sums;
            }
            

        },
        mounted: function() {
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>