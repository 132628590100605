<template>
    <el-dialog title="Tire Hotel" :visible.sync="showPopup" class="my-dialog-class" append-to-body>
        <object :data="Constants.ServerUrl + 'hotel_anvelope/show_pdf/' + IdIntrare" type="application/pdf" width="100%" height="1000">
            <p><a :href="Constants.ServerUrl + 'hotel_anvelope/show_pdf/' + IdIntrare"></a></p>
        </object>
    </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings"
import BasePage from '@/pages/BasePage' 
import Constants from '@/backend/Constants'
export default {
    name: "Deviz_produse_pdf",
    extends: BasePage,
    data(){
        return {
            showPopup: false,           
            Constants: Constants,
            IdIntrare: 0
        }
    },
    methods: {
        show(id_intrare) {                               
            this.IdIntrare  = id_intrare
            this.showPopup  = true 
        }
    }
}
</script>