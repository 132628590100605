
<template>
    <el-card class="full-width margin-bottom30">
        <div slot="header" class="clearfix">
            <span>Fise inchise</span>
        </div> 
        <el-table :data="Results" v-loading="loading">
            <el-table-column label="Numar" prop="Id"></el-table-column>
            <el-table-column label="Client" prop="NumeClient"></el-table-column>  
            <el-table-column label="Client" prop="NumeMecanic"></el-table-column>  

            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >                   
                    <el-tooltip content="View PDF" >
                        <el-button type="success" plain size="small" icon="el-icon-view" @click="show_fisa(scope.row.Id)" />
                    </el-tooltip>                    
                </template>
            </el-table-column>
        </el-table>
    
        <FisaPdf ref='fisaPdf'/>
    </el-card>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import FisaPdf from "@/pages/fisa_intrare/Fisa_intrare_pdf"

export default {
    name: "FiseCard",
    extends: BasePage,
    props:['Masina'],    
    components: {
        'base-page': BasePage,
        FisaPdf
    },
    data () {
        return {
            Results: [],
            loading : true        
        }
    },
    methods: {

        async refresh_info(){
            var response = await this.post("fisa_intrare/get_fise_inchise", { idMasina:this.Masina.Id } );
            this.Results = response.Results;
            this.loading = false
        },              

        async show_fisa(id){
            this.$refs.fisaPdf.show(id)
        }
    },
    mounted(){
        this.refresh_info();
    }
};
</script>

<style lang="less" >

   .margin-bottom30 {
      margin-bottom:30px;
    }

</style>
