<template>
    <el-card class="full-width margin-bottom30"> 
        <div slot="header" class="clearfix">
            <span>Inspectii finale</span>            
        </div>
        <el-table class="full-width" :data="Inspectii" v-loading="loading">
          <el-table-column label="Data creare" prop="DataCreare"></el-table-column>                   
          <el-table-column label="Client" prop="NumeClient"></el-table-column>
          <el-table-column label="Frane" prop="Frane"></el-table-column>
          <el-table-column label="Noxe" prop="Noxe"></el-table-column>
          <!--<el-table-column label="Parametri reglaje" prop="ParametriReglaje"></el-table-column>-->
          <el-table-column label="Geometrie" prop="Geometrie"></el-table-column>
          <!--<el-table-column label="Proba de drum" prop="ProbaDeDrum"></el-table-column>-->
          <el-table-column label="Faruri" prop="Faruri"></el-table-column>
          <el-table-column label="Actiuni">    
              <template slot-scope="scope">        
                <el-tooltip class="item" effect="dark" content="Download PDF" >                    
                    <el-button type="success" plain size="small" icon="el-icon-download" @click="download_pdf(scope.row)"></el-button>
                </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
    </el-card>
</template>

<script>
    import settings from "@/backend/LocalSettings"
    import BasePage from '@/pages/BasePage'
    import moment from 'moment';

    export default {
        name: "Inspectie_finala_card",
        extends: BasePage,
        props:['Masina'],
        data(){
          return {
              Inspectii: [],
              loading : true
          }        
        },
        methods: {
           async refresh_info() {
              var response      = await this.post('inspectii_finale/get_inspectii_finale',{masina: this.Masina})
              this.Inspectii    = response.Results;
              this.loading      = false
           },           

           download_pdf(item) {             
              window.open("/#/inspectie_finala_pdf/" + item.Id, '_blank');
           },

          
        },
         mounted: function(){
            this.refresh_info();
        }
               
    }
</script>

<style lang="less">
    .full-width {
        width: 100%;
    }

       
</style>