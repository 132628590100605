
<template>
    <el-card class="full-width margin-bottom30">
        <div slot="header" class="clearfix">
            <span>Facturi proforme</span>
        </div> 
        <el-table :data="Results" v-loading="loading">
            <el-table-column prop='Serie' label='Serie'></el-table-column>
            <el-table-column prop='Numar' label='Numar'></el-table-column>            
            <el-table-column label='Data emitere'>
                <template slot-scope='scope'>
                    {{ scope.row.DataEmitere | momentformat('dd.MM.YYYY HH:mm') }} 
                </template>
            </el-table-column>
            <!-- <el-table-column prop='ValoareCuTva' label='Valoare cu tva'></el-table-column> -->
            <el-table-column prop='ValoareFaraTva' label='Valoare'>
                <template slot-scope="scope">
                    {{scope.row.ValoareFaraTva | format_money}} lei
                </template>
            </el-table-column>
            <el-table-column prop='NumeClient' label='Nume client'></el-table-column>                     
            <el-table-column prop='StatusPlata' label='Status plata'></el-table-column>
            <el-table-column prop='Status' label='Status'></el-table-column>            
            <el-table-column fixed="right" label="Actiuni" width="200px" >
                <template slot-scope="scope" >
                    <el-tooltip v-if="$has_right('modificare-factura')" content="Modificare">
                        <el-button type="primary" plain size="small" icon="el-icon-edit" @click="show_dialog(scope.row.Id)" />
                    </el-tooltip>
                    <el-tooltip content="Download PDF" >
                        <el-button type="success" plain size="small" icon="el-icon-download" @click="show_factura(scope.row)" />
                    </el-tooltip>
                    <el-tooltip v-if="$has_right('stergere-factura')" content="Sterge" >
                        <el-button type="danger" plain size="small" icon="el-icon-delete" @click="delete_item(scope.row)" />
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
    
        <Facturi-dialog ref='dlg' @save="salvare_factura()" />
    </el-card>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Facturi_dialog from '@/pages/facturi/Facturi_dialog.vue';

export default {
    name: "FacturiCard",
    extends: BasePage,
    props:['Masina'],    
    components: {
        'base-page': BasePage,
        'Facturi-dialog': Facturi_dialog,
    },
    data () {
        return {
            Results: [],
            loading : true        
        }
    },
    methods: {

        async refresh_info(){
            var response = await this.post("facturi/get_facturi", { idMasina:this.Masina.Id } );
            this.Results = response.Results;
            this.loading = false
        },        

        async delete_item( item ){            
            this.$confirm(`Sunteti sigur ?`, 'Warning', {               
                type: 'warning'
                }).then(async() => {
                    await this.post("facturi/delete_item", { id: item.Id } );
                    this.refresh_info(); 
                    this.$message({type: 'info', message: 'Stergere efectuata cu succes'});                                                                       
                    }).catch(() => {
                        this.$message({type: 'info', message: 'Stergere anulata'});                                                                 
                        });
        },

        async show_factura(item){
            window.open("/#/factura_pdf/" + item.Id, '_blank');
        },

        show_dialog(id){
            this.$refs.dlg.show_me(id);
        },

        salvare_factura(){
            this.$emit('facturasalvata')
        },
    },
    mounted(){
        this.refresh_info();
    }
};
</script>

<style lang="less" >

   .margin-bottom30 {
      margin-bottom:30px;
    }

</style>
