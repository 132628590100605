<template>
  <el-card class="full-width margin-bottom30"> 
    <div slot="header" class="clearfix">
        <span>Comenzi acceptate</span>
    </div>
    <el-table class="full-width" :data="Results" v-loading="loading">
      <el-table-column label="Comanda">
        <template slot-scope="scope">
          Comanda acceptata {{scope.row.Id}}
        </template>
      </el-table-column>
      <el-table-column label="Data adaugare" prop="DataCreare"></el-table-column>
      <el-table-column label="Status" prop="Status"></el-table-column>
      <el-table-column label="Actiuni">

        <template slot-scope="scope">    
          <el-tooltip class="item" effect="dark" content="Editeaza"  v-if="scope.row.Status == 'trimisa'">
            <el-button type="primary" plain size="small" icon="el-icon-edit" @click="editeaza(scope.row)"></el-button>
          </el-tooltip>

          <el-tooltip class="item" effect="dark" content="Completa"  v-if="scope.row.Status == 'trimisa'">
            <el-button type="success" plain size="small" icon="el-icon-check" @click="completa(scope.row)"></el-button>
          </el-tooltip>

          <el-tooltip class="item" effect="dark" content="Download PDF" >
            <el-button type="info" plain size="small" icon="el-icon-download" @click="download_pdf(scope.row)"></el-button>
          </el-tooltip>

          <el-tooltip class="item" effect="dark" content="Anulare si retur"  v-if="scope.row.Status == 'completa'">
            <el-button type="danger" plain size="small" icon="el-icon-close" @click="anulare_retur(scope.row)"></el-button>
          </el-tooltip>

          <el-tooltip class="item" effect="dark" content="Genereaza programare"  v-if="scope.row.Status == 'completa'">
            <el-button type="primary" plain size="small" icon="el-icon-date" @click="genereaza_programare(scope.row)"></el-button>
          </el-tooltip>
        
          <el-tooltip class="item" effect="dark" content="Genereaza deviz"  v-if="scope.row.Status == 'completa' && !parseInt(scope.row.IdDeviz) > 0">
            <el-button type="primary" plain size="small" icon="el-icon-document" @click="genereaza_deviz(scope.row)"></el-button>
          </el-tooltip> 
        </template>
        
      </el-table-column>
    </el-table>
    <add-edit-comanda ref="addEditComanda"></add-edit-comanda>
    <programari-dialog @save="programare_salvata()" ref="programariDialog"></programari-dialog>
  </el-card>
</template>

<script>
    import settings from "@/backend/LocalSettings"
    import BasePage from '@/pages/BasePage'
    import ComenziFurnizoriAddEdit from '@/components/comenzi_furnizori/ComenziFurnizoriAddEdit'
    import ProgramariDialog from '@/pages/programari/Programari_dialog'

    export default {
        name: "ComenziFurnizoriCard",
        extends: BasePage,
        props:['Masina'],
        data(){
            return {
                Results: [],
                loading : true
            }        
        },
        methods: {
           async refresh_info() {
              var response = await this.post('ComenziFurnizori/get_comenzi',{idMasina:this.Masina.Id})
              this.Results = response.Results
              this.loading = false
           },
           editeaza(comanda){
            this.$refs.addEditComanda.show_me(comanda.Id)
           },
           genereaza_programare(comanda) {
             this.$refs.programariDialog.set_checkbox_state(true)
             this.$refs.programariDialog.show_me(-1,comanda.Id)
           },
            async genereaza_deviz(item){
              this.$confirm('Sunteti sigur ca doriti emiterea devizului?', 'Atentionare', {
                  confirmButtonText: 'OK',
                  cancelButtonText: 'Anulare',
                  type: 'warning'
                }).then(async () => {                  
                  var response = await this.post('ComenziFurnizori/genereaza_deviz',{idComanda:item.Id})
                  this.$message({type: 'success',message: 'Deviz creat cu succes!'})
                  this.$emit('deviz_creat')
                  this.refresh_info()
                }).catch((e) => {
                  console.log(e);
                  
                })
              
            },
           programare_salvata() {
             this.$emit('programaresalvata')
           },
           completa(comanda) {
            this.$confirm('Sunteti sigur ca doriti marcarea comenzii "Completa" ?', 'Atentionare', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Anulare',
                type: 'warning'
              }).then(async () => {
                var response = await this.post('ComenziFurnizori/completa',{idComanda:comanda.Id})
                this.$message({type: 'success',message: 'Comanda a fost modificata!'})
                this.refresh_info()
              }).catch(() => {
                // fac nimic
              })
           },
          
           download_pdf(item) {             
             window.open("/#/comanda_furnizor_pdf/" + item.Id, '_blank');
           },
           
           anulare_retur(comanda) {
             this.$confirm('Sunteti sigur ca doriti anularea comenzii?', 'Atentionare', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Anulare',
                type: 'warning'
              }).then(async () => {
                var response = await this.post('ComenziFurnizori/anuleaza',{idComanda:comanda.Id})
                this.$message({type: 'success',message: 'Comanda a fost anulata!'})
                this.refresh_info()
              }).catch(() => {
                // fac nimic
              })
           }
        },
        components: {
          'add-edit-comanda': ComenziFurnizoriAddEdit,
          'programari-dialog' : ProgramariDialog
        },
        mounted: function(){
            this.refresh_info();
        }
    }
</script>

<style lang="less">
    .full-width {
        width: 100%;
    }

    .margin-bottom30 {
      margin-bottom:30px;
    }
       
</style>