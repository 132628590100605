<template>
    <el-card class="full-width margin-bottom30"> 
        <div slot="header" class="clearfix">
            <span>Remindere</span> 
            <span><el-button style="float: right;" type="primary" plain size="small" icon="el-icon-plus" round @click="adauga(Masina)"></el-button></span>                       
        </div>
        <el-table :data="Info" v-loading="loading" :row-class-name="tableRowClassName">
            <el-table-column prop="Mesaj" label="Mesaj"></el-table-column>
            <el-table-column prop="DataExecutie" label="Data"></el-table-column>            
            <el-table-column prop="Status" label="Status"></el-table-column>            
            <el-table-column label="Actiuni">
                <template slot-scope="scope">                    
                    <el-tooltip v-if="scope.row.Status == 'creat'" content="Rezolvat"> 
                        <el-button type="success" plain size="small" icon="el-icon-success"  @click="rezolvat(scope.row)" />
                    </el-tooltip>
                    <el-tooltip v-if="scope.row.Status == 'creat'" content="Amana"> 
                        <el-button type="warning" plain size="small" icon="el-icon-alarm-clock"  @click="show_amanare(scope.row)" />
                    </el-tooltip>
                    <!-- <el-tooltip v-if="scope.row.Status == 'creat'" content="Sterge"> 
                        <el-button type="danger" plain size="small" icon="el-icon-delete"  @click="sterge(scope.row)" />
                    </el-tooltip> -->
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="Amana reminder" :visible.sync="Amanare.ArataAmanare" width="30%" append-to-body >
          <p>Data amanare:</p>
          <el-date-picker  class='full-width' v-model='Amanare.DataNouaExecutie' type='datetime' value-format='yyyy-MM-dd HH:mm:ss' format='dd.MM.yyyy HH:mm' />
          <span slot="footer" class="dialog-footer">
            <el-button @click="Amanare.ArataAmanare = false">Anuleaza</el-button>
            <el-button type="primary" @click="amana()">Confirma</el-button>
          </span>
        </el-dialog>
       <Remindere-dialog ref="reminder" @salveaza="save"/>
    </el-card>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage'
    import Remindere_dialog from '@/pages/remindere/Remindere_dialog.vue'
    import moment from 'moment';

    export default {
        name: "Remindere",
        extends: BasePage,
        props:['Masina'],
        components: {
            'Remindere-dialog': Remindere_dialog
        },
        data(){
            return {
              Info: [], 
              Amanare: {DataNouaExecutie:'',IdReminder:'',ArataAmanare:false}  ,
              loading: true,
              
              
            }
        },
        methods: {
            adauga(item){            
                this.$refs.reminder.show_me(item.Id);
            },
            async get_info(){
              
                var response  = await this.post('remindere/get_remindere', {masina: this.Masina})
                this.Info     = response.Results
                this.loading = false
            },
            async rezolvat(item) {
              this.$confirm('Marcam remider-ul realizat?', 'Atentie', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Anuleaza',
                type: 'warning'
              }).then(async () => {
                var response = await this.post('remindere/rezolvat', {item: item})
                this.$message({type: 'success',message: 'Remider-ul a fost realizat!'});
                this.get_info()
              }).catch(() => {
                //fac nimic
              });
            },
            tableRowClassName({row, rowIndex}) {               
              if (row.DataExecutie < moment().format("YYYY-MM-DD HH:mm:ss") && row.EsteValidat === '0' && row.Status === 'creat') {
                return 'blinkable';
              }
              return '';
            },
            show_amanare(item) {
              this.Amanare.DataNouaExecutie = moment(item.DataExecutie).add(3, 'days').format("YYYY-MM-DD HH:mm:ss");
              this.Amanare.IdReminder       = item.Id
              this.Amanare.ArataAmanare     = true
            },    
            async amana(item) {
              var response = await this.post('remindere/amana', {amanare: this.Amanare})
              this.Amanare.ArataAmanare = false
              this.get_info()
            },    
            async sterge(item) {
              this.$confirm('Sunteti sigur ca doriti stergerea reminder-ului?', 'Atentie', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Anuleaza',
                type: 'warning'
              }).then(async () => {
                var response = await this.post('remindere/sterge', {item: item})
                this.$message({type: 'success',message: 'Remider-ul a fost sters!'});
                this.get_info()
              }).catch(() => {
                //fac nimic
              });
            },        
            async save(item){
              var response = await this.post('remindere/set_reminder', {masina: this.Masina.Id, reminder: item})
              this.get_info(this.Masina);
            },
            
        },
        mounted() {
          
            this.get_info()

}
    }
</script>

<style>
    .full-width {
        width: 100%;
    }
    
    .margin-bottom30 {
      margin-bottom:30px;
    }
     .el-table .warning-row {
      background: rgb(226, 62, 12);
      color: white;
    }

    .blinkable {
      background:white;
      color:black;
      animation: blink 1s infinite; 
      /* animation-name: blink;  
      animation-duration: 1s; 
      animation-iteration-count: infinite; */
    }

    @keyframes blink {
      from { background-color: white; }
      to { background-color: red;color:white; }
    }
       
</style>
