<template>
    <el-dialog title="Generare comanda" :visible.sync="ArataDialog" width="60%"  append-to-body>
      <el-row>
        <el-col :md="8" :offset="8" style="text-align: center;">
          Selecteaza produse
        </el-col>
      </el-row>
      <el-row v-loading='loading'>
       
        <el-col :md="20" :offset="2">
          <el-row style="margin-top:10px;margin-bottom:15px;border-bottom: 2px solid #f2f5f7;"> 
            <el-col :md="16"><h6 >Produse</h6></el-col>
            <el-col :md="8"><h6 >Detalii</h6></el-col>
          </el-row>

          <el-row v-for="item in Grupe" :key="item.NrOrdine" style="margin-bottom:15px;background-color:#f5f6f7; padding:5px 10px;">
            <el-checkbox v-model="item.Selectat" @change="selectGrupa(item)" style="margin-bottom:20px;">Selecteaza toate produsele din grupa</el-checkbox>
            <el-col v-if="item.Piese.length>0" style="margin-bottom:10px;">
              <b>Piese</b>
              <el-row :gutter="15" v-for="p in item.Piese" :key="p.Id">                
                  <el-col :md="16" >{{p.NumeProdus}}</el-col>
                  <el-col :md="8">{{p.Detalii}}</el-col>
                </el-row>
            </el-col>

            <el-col v-if="item.Manopera.length>0" style="margin-bottom:10px;">
              <b>Manopera</b>
                <el-row :gutter="15" v-for="m in item.Manopera" :key="m.Id">                
                    <el-col :md="16" >{{m.NumeProdus}}</el-col>
                    <el-col :md="8">{{m.Detalii}}</el-col>
                </el-row>
            </el-col>

            <el-col v-if="item.Kit.length>0" style="margin-bottom:10px;">
              <b>Kit</b>
                <el-row :gutter="15" v-for="k in item.Kit" :key="k.Id">                
                    <el-col :md="16" >{{k.NumeProdus}}</el-col>
                    <el-col :md="8">{{k.Detalii}}</el-col>
                </el-row>
            </el-col>

          </el-row>
        </el-col>
        
      </el-row>
    <span slot="footer" class="dialog-footer">
      <p class="eroare" v-if="show_eroare"> {{mesaj_eroare}} </p>
      <el-button @click="ArataDialog = false">Anuleaza</el-button>
      <el-button type="primary" @click="genereaza_comanda()">Confirma</el-button>
    </span>
  </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage'

    export default {
        name: "PopupAdaugareProduse",
        extends: BasePage,
        data(){
          return {
              Grupe: [{Piese:[], Manopera:[], Kit:[]}],
              loading : false,
              ArataDialog : false,
              IdOferta : null,
              show_piese: false,
              show_manopera: false,
              show_kit: false,
              show_eroare: false,
              mesaj_eroare: 'Este obligatoriu sa selectati minim un produs.',
          }        
        },
        methods: {
          async show_me(idOferta) {
            this.show_eroare  = true
            this.mesaj_eroare = 'Este obligatoriu sa selectati minim un produs.'
            this.ArataDialog  = true
            this.loading      = true
            this.IdOferta     = idOferta
            var response      = await this.post('oferte/get_produse_comanda',{idOferta:idOferta})
            this.Grupe        = response.Grupe          
            this.loading      = false
          },

          async genereaza_comanda () {
                       
            if(!this.show_eroare)
            {
              this.loading = true
              var response = await this.post('ComenziFurnizori/genereaza_din_produse_oferta',{idOferta:this.IdOferta, grupe:this.Grupe})
              this.$emit('comandaGenerata');
              this.ArataDialog = false
            }
           
          }, 
         
          selectGrupa(item){            
            if(item.Selectat){
              this.Grupe.forEach(g => {
                if(g.Id == item.Id){
                  g.Piese.forEach(p => {p.Selectat = true})
                  g.Manopera.forEach(m => {m.Selectat = true})
                  g.Kit.forEach(k => {k.Selectat = true})
                }
              })
            } else {
              this.Grupe.forEach(g => {
                if(g.Id == item.Id){
                  g.Piese.forEach(p => {p.Selectat = false})
                  g.Manopera.forEach(m => {m.Selectat = false})
                  g.Kit.forEach(k => {k.Selectat = false})
                }
              })
            }

            var filter  = '';
              filter      = this.Grupe.filter(g => g.Selectat == true)
              
              if (filter != '') {
                this.show_eroare  = false
                this.mesaj_eroare = ''
              }
              else {
                this.show_eroare  = true
                this.mesaj_eroare = 'Este obligatoriu sa selectati minim un produs.'
              }

          }  
        }
    }
</script>

<style lang="less">
    .full-width {
        width: 100%;
    }  
    .eroare {
      color: red;
      font-size: 12
    }
</style>